import { IResourceComponentsProps, useUpdate } from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  DateField,
  Space,
  EditButton,
  DeleteButton,
  ShowButton,
} from "@pankod/refine-antd";
import { IUser } from "interfaces";
import type { DataNode } from 'antd/es/tree';
import { Tree } from '../../components/molecules/tree.component';
import { permissionsDataNode } from "constants/permissions";

export const UserList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, sorter } = useTable<IUser>({
    initialSorter: [
      {
        field: "id",
        order: "asc",
      },
    ],
    initialPageSize: 15,
  });

  const { mutate } = useUpdate<IUser>();



  const filterPermissions = (permissions: string) => { 
    if(!permissions) return "";
    return permissions.split(",").filter((key) => {
      const parents = permissionsDataNode.map((item: any) => {
        if(item?.children) {
          const children = item?.children.map((children: any) => {
            return children?.children ? children.key : undefined
          }).filter(Boolean);
          children.push(item.key);
          return children;
        }
        return undefined;
      }).filter(Boolean).flat();
      return !parents.includes(key)
    });
  }; 

  const handlePermissions = (keys:string[], id: any) => {
    if(!keys) return;
    const permissions = keys.join(",");
    mutate({
      resource: 'users',
      id,
      values: {
        permissions
      }
    })
  }

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("id", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="username"
          key="username"
          title="Usuario"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("username", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="name"
          key="name"
          title="Nombre"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="permissions"
          key="permissions"
          title="Permisos"
          render={(value, row: IUser) => {
            return (
              <Tree
                treeData={permissionsDataNode}
                checkedKeys={filterPermissions(value)}
                onCheck={(keys: any) => {
                  handlePermissions(keys, row.id)
                }}
              />
            );
          }}
          defaultSortOrder={getDefaultSortOrder("permissions", sorter)}
          sorter
        />
        <Table.Column<IUser>
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
