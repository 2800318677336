import { IResourceComponentsProps, usePermissions } from "@pankod/refine-core";
import {
  Create,
  Form,
  Input,
  Select,
  useForm,
  useSelect,
} from "@pankod/refine-antd";

import "react-mde/lib/styles/css/react-mde-all.css";
import { IMessageSubType } from "interfaces/message-subtype.interface";
import { IMessageType } from "interfaces/message-type.interface";

export const MessageSubTypeCreate: React.FC<IResourceComponentsProps> = () => {

  const { formProps, saveButtonProps } = useForm<IMessageSubType>();

  const { selectProps: messageTypesSelectProps } = useSelect<IMessageType>({
    resource: "message-types",
    optionLabel: "type",
    optionValue: "id"
  });

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Subtipo"
          name="subtype"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Tipo de Mensaje"
          name="id_message_type"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select 
            showSearch
            {...messageTypesSelectProps} 
          />
        </Form.Item>
      </Form>
    </Create>
  );
};
