import { Spin } from "@pankod/refine-antd";

const FullScreenLoader = () => (
  <div style={{
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    zIndex: 2000
  }}>
    <Spin size="large" />
  </div>
);

export default FullScreenLoader;
