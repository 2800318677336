import { CrudFilters, IResourceComponentsProps, useNotification, usePermissions, useShow } from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  Space,
  EditButton,
  DeleteButton,
  ShowButton,
  Modal,
  Show,
  Typography,
  CreateButton,
  Input,
  Form,
  Icons,
  Button,
  Row,
  Col,
} from "@pankod/refine-antd";
import { useState } from "react";
import { IMessageType } from "interfaces/message-type.interface";

const { Title, Text } = Typography;

export const MessageTypeList: React.FC<IResourceComponentsProps> = () => {

  const { tableProps, sorter, searchFormProps } = useTable<IMessageType>({
    initialSorter: [
      {
        field: "id",
        order: "asc",
      },
    ],
    initialPageSize: 15,
    onSearch: (params: any) => {
      const filters: CrudFilters = [];
      const { q } = params;
      filters.push(
          {
              field: 'name_like',
              operator: 'eq',
              value: q,
          },
      );
      return filters;
    },
  });

  const [visible, setVisible] = useState(false);
  const { queryResult, showId, setShowId } = useShow<IMessageType>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <>
      <List
        headerButtons={() => <CreateButton type="primary">Nuevo Tipo de Mensaje</CreateButton> }
      >
        <Form layout="vertical" {...searchFormProps}>
          <Row gutter={[20, 0]}>
            <Col span={12}>
              <Form.Item name="q">
                  <Input
                      placeholder="Tipo"
                      prefix={<Icons.SearchOutlined />}
                  />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                  <Button htmlType="submit" type="primary">
                      Buscar
                  </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Table {...tableProps} rowKey="id">
          <Table.Column
            dataIndex="id"
            key="id"
            title="id"
            render={(value) => <TextField value={value} />}
            defaultSortOrder={getDefaultSortOrder("id", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="type"
            key="type"
            title="Tipo"
            render={(value) => <TextField value={value} />}
            defaultSortOrder={getDefaultSortOrder("type", sorter)}
            sorter
          />
          <Table.Column<IMessageType>
            title="Acciones"
            dataIndex="actions"
            render={(_, record) => (
              <Space>
                <EditButton hideText size="small" recordItemId={record.id} />
                <ShowButton hideText size="small" recordItemId={record.id}  
                  onClick={() => {
                    setShowId(record.id);
                    setVisible(true);
                }} />
                <DeleteButton hideText size="small" recordItemId={record.id} />
              </Space>
            )}
          />
        </Table>
      </List>
      <Modal visible={visible} onCancel={() => setVisible(false)} onOk={() => setVisible(false)}>
        <Show 
          isLoading={isLoading}
          recordItemId={showId}
          headerProps={{
            title: 'Persona'
          }}
          headerButtons={<></>}
        >
          <Title level={5}>ID</Title>
          <Text>{record?.id}</Text>
          <Title level={5}>Tipo</Title>
          <Text>{record?.type}</Text>
        </Show>
      </Modal>
    </>
  );
};
