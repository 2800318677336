import React, { ComponentProps, CSSProperties, useEffect, useState } from "react";
import { AntdLayout, Menu, Grid, Title } from "@pankod/refine-antd";
import { LogoutOutlined, UnorderedListOutlined } from "@ant-design/icons";
import {
    useTranslate,
    useLogout,
    ITreeMenu,
    useIsExistAuthentication,
    useCanWithoutCache,
    useRouterContext,
} from "@pankod/refine-core";

import { useMenu } from "../../hooks/use-menu";

type ItemType = NonNullable<ComponentProps<typeof Menu>["items"]>[number];

export const Sider: React.FC = () => {
    const [collapsed, setCollapsed] = useState<boolean>(false);
    const isExistAuthentication = useIsExistAuthentication();
    const { Link } = useRouterContext();
    const { mutate: logout } = useLogout();
    const translate = useTranslate();
    const { menuItems, selectedKey, defaultOpenKeys } = useMenu();
    const breakpoint = Grid.useBreakpoint();
    const { can: checkCanAccess } = useCanWithoutCache();
    const [renderedMenuItems, setRenderedMenuItems] = React.useState<
        Array<ItemType>
    >([]);

    const isMobile = !breakpoint.lg;

    const CustomTitle = (props: any) => {
        const className = props?.collapsed ? 'menu-logo-collapsed' : 'menu-logo';
        return <div><img src="/images/logo-vasconcelos.png" alt="Preparatoria Vasconcelos" className={className} /></div>;
    };

    const handleTreeMenuItem = React.useCallback(
        async (
            item: ITreeMenu,
            selectedKey?: string,
        ): Promise<ItemType | undefined> => {
            if (item.children?.length > 0) {
                const handledChildren = await Promise.all(
                    item.children.map((child) =>
                        handleTreeMenuItem(child, selectedKey),
                    ),
                );
                // is sub
                if (handledChildren.length > 0) {
                    return {
                        key: item.name,
                        label: item.label,
                        icon: item.icon ?? <UnorderedListOutlined />,
                        title: item.label,
                        children: handledChildren,
                    } as ItemType;
                }

                return undefined;
            } else {
                const isSelected = item.route === selectedKey;
                const isRoute = !(
                    item.parentName !== undefined && item.children.length === 0
                );

                const { can: hasAccess } = checkCanAccess
                    ? await checkCanAccess({
                        resource: item.name,
                        action: "list",
                    })
                    : { can: true };
                // is leaf
                if (hasAccess) {
                    const LabelWrapper = item.route
                        ? Link
                        : (React.Fragment as typeof Link);
                    return {
                        key: item.name,
                        label: (
                            <LabelWrapper href={item.route} to={item.route}>
                                {item.label}
                                {!collapsed && isSelected && (
                                    <div className="ant-menu-tree-arrow" />
                                )}
                            </LabelWrapper>
                        ),
                        icon:
                            item.icon ?? (isRoute && <UnorderedListOutlined />),
                        style: isSelected ? { fontWeight: "bold" } : undefined,
                    } as ItemType;
                }
                return undefined;
            }
        },
        [collapsed, checkCanAccess],
    );

    const renderTree = React.useCallback(
        async (items: ITreeMenu[], selected: string) => {
            const promises = items.map((item) =>
                handleTreeMenuItem(item, selected),
            );
            setRenderedMenuItems(
                (await Promise.all(promises)).filter(Boolean) as ItemType[],
            );
        },
        [],
    );

    useEffect(() => {
        renderTree(menuItems, selectedKey);
    }, [menuItems, selectedKey]);

    return (
        <AntdLayout.Sider
            collapsible
            collapsed={collapsed}
            onCollapse={(collapsed: boolean): void => setCollapsed(collapsed)}
            collapsedWidth={isMobile ? 0 : 80}
            breakpoint="lg"
            style={isMobile ? antLayoutSiderMobile : antLayoutSider}
            width={250}
        >
            <CustomTitle collapsed={collapsed} />
            <Menu
                selectedKeys={[selectedKey]}
                defaultOpenKeys={defaultOpenKeys}
                mode="inline"
                onClick={() => {
                    if (!breakpoint.lg) {
                        setCollapsed(true);
                    }
                }}
                items={[
                    ...renderedMenuItems,
                    ...(isExistAuthentication
                        ? [
                            {
                            key: "logout",
                            label: translate("buttons.logout", "Salir"),
                            onClick: () => logout(),
                            icon: <LogoutOutlined />,
                            } as ItemType,
                        ]
                        : []),
                ]}
            />
        </AntdLayout.Sider>
    );
};

const antLayoutSider: CSSProperties = {
    position: "relative",
};
const antLayoutSiderMobile: CSSProperties = {
    position: "fixed",
    height: "100vh",
    zIndex: 999,
};