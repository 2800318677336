import { CrudFilters, IResourceComponentsProps, useNotification, usePermissions, useResource, useShow } from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  Space,
  EditButton,
  DeleteButton,
  ShowButton,
  Modal,
  Show,
  Typography,
  CreateButton,
  Input,
  Form,
  Icons,
  Button,
  Row,
  Col,
  Spin,
} from "@pankod/refine-antd";
import { useEffect, useState } from "react";
import { IMessage } from "interfaces/message.interface";
import { getRequest, postRequest } from "utils";
import FullScreenLoader from "components/atoms/fullscreenloader.atom";

const { Title, Text } = Typography;

export const MessageList: React.FC<IResourceComponentsProps> = () => {

  const { 
    resource: { 
      name: resourceName = null,
      options: { 
        message_type = null, 
        message_subtype = null 
      } = {} 
    } = {} 
  } = useResource();

  const { tableProps, sorter, searchFormProps, setFilters } = useTable<IMessage>({
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
    initialPageSize: 15,
    onSearch: (params: any) => {
      const filters: CrudFilters = [];
      const { q } = params;
      filters.push(
          {
              field: 'name_like',
              operator: 'eq',
              value: q,
          },
      );
      return filters;
    },
    resource: 'messages',
    initialFilter: [
      {
        field: "message_type",
        operator: "eq",
        value: message_type,
      },
      {
        field: "message_subtype",
        operator: "eq",
        value: message_subtype,
      },
    ]
  });

  useEffect(() => {
    setFilters([
      {
        field: "message_type",
        operator: "eq",
        value: message_type,
      },
      {
        field: "message_subtype",
        operator: "eq",
        value: message_subtype,
      },
    ]);
  }, [message_type, message_subtype]);

  const [visible, setVisible] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false);
  const [confirmModalIsVisible, setConfirmModalIsVisible] = useState(false);
  const [confirmDialogList, setConfirmDialogList] = useState([]);
  const [confirmDialogColumns, setConfirmDialogColumns] = useState([{}]);
  const [confirmDialogInstructions, setConfirmDialogInstructions] = useState("");
  const [confirmDialogActionUrl, setConfirmDialogActionUrl] = useState("");
  const [debtorsList, setDebtorsList] = useState([]);
  const { queryResult, showId, setShowId } = useShow<IMessage>({ resource: 'messages' });
  const { data, isLoading: isLoadingQuery } = queryResult;
  const record = data?.data;

  const formatCurrency = (value: number): string => {
    return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
  };

  const handlePaymentRemindersListButton = async() => {
    const response: any = await getRequest("/messages/payment-reminder");
    if(response && response.data) {
      setConfirmDialogInstructions("Se enviara el mensaje de Recordatorio de pago a las siguientes personas:");
      setConfirmDialogColumns([{title:'Matricula', dataIndex: 'matricula'},{title:'Nombre', dataIndex: 'nombre'}]);
      setConfirmDialogList(response.data);
      setConfirmDialogActionUrl("/messages/payment-reminder");
    }
    setConfirmModalIsVisible(true);
  }

  const handleDebtorsListButton = async() => {
    const response: any = await getRequest("/messages/debtors");
    if(response && response.data) {
      setConfirmDialogInstructions("Se enviara el mensaje de Cobranza a las siguientes personas:");
      setConfirmDialogColumns([{title:'Matricula', dataIndex: 'matricula'},{title:'Nombre', dataIndex: 'nombre'},{title:'Meses', dataIndex: 'meses'},{title:'Adeudo', dataIndex: 'saldo', render: (_:any, record:any) => formatCurrency(record.saldo) }]);
      setConfirmDialogList(response.data);
      setConfirmDialogActionUrl("/messages/debtors");
    }
    setConfirmModalIsVisible(true);
  }

  const handleConfirmModalButton = async() => {
    setIsLoading(true);
    const response: any = await postRequest(confirmDialogActionUrl);
    console.log(response.data);
    setIsLoading(false);
    setConfirmModalIsVisible(false);
  }

  let headersButtons = <></>;
  if(resourceName === "general") {
    headersButtons = <CreateButton type="primary">Nuevo Mensaje</CreateButton>;
  }
  if(resourceName === "payments") {
    headersButtons = <>
      <Button type="primary" onClick={handlePaymentRemindersListButton}>Enviar Recordatorios de Pago</Button>
      <Button type="primary" onClick={handleDebtorsListButton}>Enviar Cobranza</Button>
    </>;
  }

  return (
    <>
      { isLoading && <FullScreenLoader  /> }
      <List
        headerButtons={() => headersButtons}
      >
        <Form layout="vertical" {...searchFormProps}>
          <Row gutter={[20, 0]}>
            <Col span={12}>
              <Form.Item name="q">
                  <Input
                      placeholder="Tipo"
                      prefix={<Icons.SearchOutlined />}
                  />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                  <Button htmlType="submit" type="primary">
                      Buscar
                  </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Table {...tableProps} rowKey="id">
          <Table.Column
            dataIndex="id"
            key="id"
            title="id"
            render={(value) => <TextField value={value} />}
            defaultSortOrder={getDefaultSortOrder("id", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="content"
            key="content"
            title="Mensaje"
            render={(value) => <TextField value={value} />}
            defaultSortOrder={getDefaultSortOrder("type", sorter)}
            sorter
          />
          <Table.Column
            dataIndex={["message_type", "type"]}
            key="id_message_type"
            title="Tipo de Mensaje"
            render={(value) => <TextField value={value} />}
            defaultSortOrder={getDefaultSortOrder("id_message_type", sorter)}
            sorter
          />
          <Table.Column
            dataIndex={["message_subtype", "subtype"]}
            key="id_message_subtype"
            title="Subtipo de Mensaje"
            render={(value) => <TextField value={value} />}
            defaultSortOrder={getDefaultSortOrder("id_message_subtype", sorter)}
            sorter
          />
          <Table.Column<IMessage>
            title="Acciones"
            dataIndex="actions"
            render={(_, record) => (
              <Space>
                <ShowButton hideText size="small" recordItemId={record.id}  
                  onClick={() => {
                    setShowId(record.id);
                    setVisible(true);
                }} />
              </Space>
            )}
          />
        </Table>
      </List>
      <Modal visible={confirmModalIsVisible} onCancel={() => setConfirmModalIsVisible(false)} onOk={handleConfirmModalButton} width={800}>
        <div>{confirmDialogInstructions}</div>
        { confirmDialogList && (
          <Table dataSource={confirmDialogList} columns={confirmDialogColumns}></Table>
        )}
        
      </Modal>
      <Modal visible={visible} onCancel={() => setVisible(false)} onOk={() => setVisible(false)}>
        <Show 
          isLoading={isLoadingQuery}
          recordItemId={showId}
          headerProps={{
            title: 'Mensaje'
          }}
          headerButtons={<></>}
        >
          <Title level={5}>ID</Title>
          <Text>{record?.id}</Text>
          <Title level={5}>Contenido</Title>
          <Text>{record?.content}</Text>
        </Show>
      </Modal>
    </>
  );
};
