import { TreeDataNode } from "antd";

export const permissionsDataNode: TreeDataNode[] = [
    {
        title: 'Dashboard',
        key: 'dashboard:index',
    },
    {
        title: 'Mensajes',
        key: 'messages:menu',
        children: [
            {
                title: 'General',
                key: 'general:index'
            },
            {
                title: 'Recordatorios y Cobranza',
                key: 'payments:index'
            },
            {
                title: 'Agradecimientos de Pago',
                key: 'payments-thanks:index'
            }
        ]
    },
    {
        title: 'Configuración',
        key: 'management:menu',
        children: [
            {
                title: 'Tipos de Mensaje',
                key: 'message-types:index'
            },
            {
                title: 'Subtipos de Mensaje',
                key: 'message-subtypes:index'
            },
            {
                title: 'Usuarios',
                key: 'users:index'
            }
        ]
    }
];

export const PermissionsConstants = {
    PERSONS_INDEX: 'persons:index',
    PERSONS_GOD: 'persons:god',
}