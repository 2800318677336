import { IResourceComponentsProps, usePermissions, useNavigation } from "@pankod/refine-core";
import {
  Create,
  Form,
  Input,
  Select,
  useForm,
  useSelect,
  TextField
} from "@pankod/refine-antd";
import { IMessage } from "interfaces/message.interface";

import "react-mde/lib/styles/css/react-mde-all.css";
import { IMessageType } from "interfaces/message-type.interface";
import { IGroup } from "interfaces/group.interface";
import { useState } from "react";
import { IMessageSubType } from "interfaces/message-subtype.interface";
import { IStudent } from "interfaces/student.interface";

export const MessageCreate: React.FC<IResourceComponentsProps> = () => {

  const { list } = useNavigation();

  const { form, formProps, saveButtonProps, redirect, onFinish } = useForm<IMessage>({ 
    resource: 'messages',
    redirect: false,
    onMutationSuccess: () => {
      list("messages/general")
    },
  });

  const [selectedMessageType, setMessageType] = useState(99);
  const [selectedShift, setSelectedShift] = useState(99);
  const [selectedGrade, setSelectedGrade] = useState(99);
  const [selectedGroup, setSelectedGroup] = useState(99);
  const [messageSubTypeOptions, setMessageSubTypeOptions] = useState([{value: 0, label: 'NINGUNO'}]);
  const [groupSelectOptions, setGroupSelectOptions] = useState([{value: 99, label: 'TODOS'}]);
  const [studentSelectOptions, setStudentSelectOptions] = useState([{value: 999, label: 'TODOS'}]);

  const { selectProps: messageTypesSelectProps } = useSelect<IMessageType>({
    resource: "message-types",
    optionLabel: "type",
    optionValue: "id"
  });

  const { selectProps: messageSubTypesSelectProps } = useSelect<IMessageSubType>({
    resource: "message-subtypes",
    optionLabel: "name",
    optionValue: "id",
    queryOptions: {
      enabled: selectedMessageType !== 99,
      onSuccess: (data) => {
        console.log(data.data.map((option:any) => ({ value: option.id, label: option.subtype })));
        const options = [
          {value: 0, label: 'NINGUNO'},
          ...data.data.map((option:any) => ({ value: option.id, label: option.subtype }))
        ];
        setMessageSubTypeOptions(options);
      }
    },
    filters: [
      {
        field: 'message_type',
        operator: 'eq',
        value: selectedMessageType,
      },
    ]
  });

  const { selectProps: groupSelectProps } = useSelect<IGroup>({
    resource: "messages/groups",
    optionLabel: "name",
    optionValue: "id",
    queryOptions: {
      enabled: selectedGrade !== 99,
      onSuccess: (data) => {
        console.log(data.data.map((option:any) => ({ value: option.id, label: option.name })));
        const options = [
          {value: 99, label: 'TODOS'},
          ...data.data.map((option:any) => ({ value: option.id, label: option.name }))
        ];
        setGroupSelectOptions(options);
      }
    },
    filters: [
      {
        field: 'id_shift',
        operator: 'eq',
        value: selectedShift,
      },
      {
        field: 'id_grade',
        operator: 'eq',
        value: selectedGrade,
      }
    ]
  });

  const { selectProps: studentsSelectProps } = useSelect<IStudent>({
    resource: "messages/students",
    optionLabel: "name",
    optionValue: "id",
    queryOptions: {
      enabled: selectedGroup !== 99,
      onSuccess: (data) => {
        console.log(data.data.map((option:any) => ({ value: option.id, label: option.name })));
        const options = [
          {value: 999, label: 'TODOS'},
          ...data.data.map((option:any) => ({ value: option.id, label: option.name }))
        ];
        setStudentSelectOptions(options);
      }
    },
    filters: [
      {
        field: 'id_shift',
        operator: 'eq',
        value: selectedShift,
      },
      {
        field: 'id_grade',
        operator: 'eq',
        value: selectedGrade,
      },
      {
        field: 'id_group',
        operator: 'eq',
        value: selectedGroup,
      }
    ]
  });

  const handleMessageTypeChange = (value: any) => {
    setMessageType(value);
    setMessageSubTypeOptions([{value: 0, label: 'NINGUNO'}]);
    form.setFieldsValue({ id_message_subtype: 0 });
  }

  const handleShiftChange = (value: any) => {
    setSelectedShift(value);
    setSelectedGroup(99);
    setGroupSelectOptions([{value: 99, label: 'TODOS'}]);
    setStudentSelectOptions([{value: 999, label: 'TODOS'}]);
    form.setFieldsValue({ id_group: 99, id_student: 999 });
  }

  const handleGradeChange = (value: any) => {
    setSelectedGrade(value);
    setGroupSelectOptions([{value: 99, label: 'TODOS'}]);
    setSelectedGroup(99);
    setStudentSelectOptions([{value: 999, label: 'TODOS'}]);
    form.setFieldsValue({ id_group: 99, id_student: 999 });
  }

  const handleGroupChange = (value: any) => {
    setSelectedGroup(value);
    if(value == 99) {
      setStudentSelectOptions([{value: 999, label: 'TODOS'}]);
    }
    form.setFieldsValue({ id_student: 999 });
  } 

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Tipo de Mensaje"
          name="id_message_type"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select 
            showSearch
            {...messageTypesSelectProps} 
            onChange={handleMessageTypeChange}
          />
        </Form.Item>
        <Form.Item
          label="Subtipo de Mensaje"
          name="id_message_subtype"
          rules={[
            {
              required: false,
            },
          ]}
          initialValue={0}
        >
          <Select 
            showSearch
          >
            { messageSubTypeOptions && messageSubTypeOptions.map((option) => (
              <Select.Option key={option.value} value={option.value}>
                { option.label }
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Turno"
          name="id_shift"
          rules={[
            {
              required: true,
            },
          ]}
          initialValue={99}
        >
          <Select
            options={[
              { label: 'TODOS', value: 99 },
              { label: 'MATUTINO', value: 1 },
              { label: 'VESPERTINO', value: 2 },
            ]}
            onChange={handleShiftChange}
          />
        </Form.Item>
        <Form.Item
          label="Grado"
          name="id_grade"
          rules={[
            {
              required: true,
            },
          ]}
          initialValue={99}
        >
          <Select
            options={[
              { label: 'TODOS', value: 99 },
              { label: 'PRIMERO', value: 1 },
              { label: 'SEGUNDO', value: 2 },
              { label: 'TERCERO', value: 3 },
            ]}
            onChange={handleGradeChange}
          />
        </Form.Item>
        <Form.Item
          label="Grupo"
          name="id_group"
          rules={[
            {
              required: false,
            },
          ]}
          initialValue={99}
        >
          <Select 
            disabled={selectedGrade == 99}
            onChange={handleGroupChange}
          >
            { groupSelectOptions.map((option) => (
              <Select.Option key={option.value} value={option.value}>
                { option.label }
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Alumno"
          name="id_student"
          rules={[
            {
              required: false,
            },
          ]}
          initialValue={999}
        >
          <Select 
            disabled={selectedGroup == 99}
            //onChange={handleGroupChange}
          >
            { studentSelectOptions.map((option) => (
              <Select.Option key={option.value} value={option.value}>
                { option.label }
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Mensaje"
          name="content"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input.TextArea rows={6} />
        </Form.Item>
      </Form>
    </Create>
  );
};
