import { FC, useEffect, useState } from 'react'
import { Tree as AntTree } from 'antd'

export const Tree: FC<any> = ({ treeData, checkedKeys: selectedKeys, onCheck }) => {
  const [checkedKeys, setCheckedKeys] = useState<string[]>(selectedKeys)
  
  useEffect(() => {
    setCheckedKeys(selectedKeys)
  }, [selectedKeys, setCheckedKeys])

  const onCheckInternal =(keys: any, event: any) => {
    const allKeys: string[] = [
      ...(event.halfCheckedKeys || []),
      ...keys,
    ]
    setCheckedKeys(keys)
    onCheck && onCheck(allKeys)
  }
  
  return (
    <AntTree
      checkable
      selectable={false}
      onCheck={onCheckInternal}
      checkedKeys={checkedKeys}
      treeData={treeData}
    />
  )
}